import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';
import { useRecoilValue } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';

import { SaleAvailabilityBanner } from '../../components/sale-availability-banner/sale-availability-banner';
import { useWidgetUpdatedSubscription } from '../../hooks/use-widget-updated-subscription';
import { brandState } from '../../stores/brand';
import { isWaitingForSaleState } from '../../stores/sales';
import { isScopeAuthorized } from '../../utils/auth';
import { Commitments } from './commitments';
import { GettingStarted } from './getting-started';
import { HomeOrders } from './home-orders';
import { HomeSaleWidgets } from './home-sale-widgets';
import { HomeStats } from './home-stats';
import { SaleConfiguration } from './sale-configuration';

export const HomeScreen: React.FC = () => {
  const { user } = useAuth0();
  const brand = useRecoilValue(brandState);
  const { i18n, t } = useTranslation();

  const isWaitingForSale = useRecoilValue(isWaitingForSaleState);

  useTitle(`${brand?.name ?? ''} ~ ${t('sidebar.home')}`);

  useWidgetUpdatedSubscription();

  const todayDate = new Date().toLocaleDateString(i18n.language, {
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  });

  return (
    <main className="relative flex-1 overflow-y-auto p-4 sm:px-10 sm:pb-22 sm:pt-10">
      <div className="flex flex-col gap-10 md:max-w-[55rem] xl:max-w-[74rem] xl:flex-row">
        <div className="flex flex-1 flex-col gap-10">
          {!isWaitingForSale &&
          isScopeAuthorized(user, 'scope.brand.analytics.view') ? (
            <HomeStats />
          ) : null}

          <section className="flex flex-col gap-2">
            <div className="text-sm uppercase text-[#767A7C]">{todayDate}</div>
            <h3 className="text-3.5xl font-bold text-gray-900">
              {t('home.hello_name', {
                name:
                  user?.['https://appchoose.io/claims/firstname'] ??
                  user?.given_name,
              })}
            </h3>
          </section>

          <div className="flex flex-col gap-6">
            {isWaitingForSale ? (
              <>
                <SaleAvailabilityBanner />
                <SaleConfiguration />
              </>
            ) : (
              <>
                <HomeSaleWidgets />
                <HomeOrders />
              </>
            )}
          </div>
        </div>
        <aside className="flex flex-col gap-6 xl:max-w-[26.5rem]">
          <GettingStarted />
          <Commitments />
        </aside>
      </div>
    </main>
  );
};

HomeScreen.displayName = 'HomeScreen';
